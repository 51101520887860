<template>
    <div class="lookwhere">
        <div class="lookwhere_info" v-if="orderDetails.data?.kdName && orderDetails.data?.billCode">
            <span><img v-for="(item, index) in orderImg" :key="index" :src="item" />{{ orderDetails.data.kdName }}快递</span>
            <span style="cursor: pointer;" @click="paste">
                快递单号 : <span>{{ orderDetails.data?.billCode }}</span>&nbsp;复制
            </span>
        </div>
        <el-steps :space="80" direction="vertical" :active="0" v-if="orderNo">
            <el-step :title="item.context" :description="item.ftime" v-for="(item, index) in orderDetails.data?.vos"
                :key="index"></el-step>
        </el-steps>
        <div v-else style="width: 80%;margin: auto;padding-top: 2%;">您已提交订单,请等待系统确认</div>
    </div>
</template>

<script>
export default {
    name: 'LookWhere',
    data() {
        return {
            // orderNo: 'XS20230421000525984000262',
            orderNo: '',
            billCode:'',
            orderDetails: {},//物流详情
            orderImg: []
        }
    },
    created() {
        if (this.$route.query.orderNo && this.$route.query.orderNo != 'null' && this.$route.query.billCode != 'null') {
            this.orderNo = JSON.parse(this.$route.query.orderNo)
            this.billCode = JSON.parse(this.$route.query.billCode)
            this.getInfo()
            this.askwhere()
        }
    },
    methods: {
        async askwhere() {
            const loading = this.$loading({
                lock: true,
                text: "请稍等...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let data = {
                billCode:this.billCode,
                orderNo: this.orderNo,
                type: 0,
            }
            let res = await this.$api.askWhere(data)
            if (res && res.code == 200) {
                this.orderDetails = res
                let regPhone = /[+\(\d]{3,4}[\(\d \)-]{4,8}[\d]{4}/g
                this.orderDetails.data?.vos.forEach((item, index) => {
                    let a = item.context.replace(regPhone, '<a href="tel:$&" style="color: red;">$&</a>')
                    this.$nextTick(() => {
                        document.querySelectorAll('.el-step__title')[index].innerHTML = a;
                    })
                })
                //arr.length - index
                this.$nextTick(() => { [...document.querySelectorAll('.el-steps .el-step__icon div')].forEach((el, index, arr) => { el.innerHTML = '' }); })
            } else {
                this.$message.error(res.msg)
            }
            loading.close()
        },

        async getInfo() {
            const loading = this.$loading({
                lock: true,
                text: "请稍等...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let res = await this.$api.getInfo({
                id: this.$route.query.id,
                orderNumber:JSON.parse(this.$route.query.orderNumber),
            })
            loading.close()
            if (res && res.code == 200) {
                res.data.goodsList.forEach(item => { this.orderImg.push(item.goodsPic) })
                console.log(this.orderImg);
            } else {
                this.$message.error('网络失败请刷新重试')
            }
        },
        //复制订单编号
        paste() {
            navigator.clipboard.writeText(this.orderDetails.data?.billCode)
            this.$message.success("复制成功!")
        },
    }
}

</script>

<style lang="scss" scoped>
.lookwhere {
    font-size: max(12px, 0.8vw);

    .lookwhere_info {
        width: 80%;
        margin: auto;
        padding-top: 1%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        >span:nth-child(1) {
            white-space: nowrap;
            display: flex;
            align-items: center;

            >img {
                width: max(20px, 3vw);
                height: max(20px, 3vw);
                margin-right: 10%;
            }
        }

        @media screen and (max-width: 600px) {
            flex-direction: column;
            align-items: start;
            gap: 10px;
        }
    }

    .el-steps {
        width: 80%;
        margin: auto;
        padding-top: 1%;

        .el-step:nth-child(1) {
            :deep(.el-step__title) {
                color: #439C4C !important;
            }

            :deep(.el-step__icon) {
                background-color: #439C4C !important;
            }
        }

    }
}
</style>